import * as z from "zod";

// *** NOTE: importing from @shared/utils is not working ***
// *** so we are importing with relative path the functions here ***
// It create a circular dependency
import { getAsNumber } from "../../../utils/get-as-number";
import { isEmpty } from "../../../utils/is-empty";
import { LenderEnum } from "../../generic";
import { amountSchema } from "../../target-property";

import { maturityDateSchema, pastDateSchema } from "./date.validation";

const yesNoSchema = z.enum(["YES", "NO"], {
  errorMap: () => {
    return { message: "form:error.required" };
  },
});

const mortgageInsuranceSchema = z
  .object({
    insuranceQuestionOriginallyInsured: yesNoSchema,
    insuranceQuestionRefinanceOrRenewal: yesNoSchema,
    insuranceQuestionAddFundsOrIncreasedMortgageAmount: yesNoSchema
      .optional()
      .nullable(),
    insuranceQuestionIncreasedAmortization: yesNoSchema.optional().nullable(),
    maturityDate: maturityDateSchema,
    lastClosingDate: pastDateSchema.optional().nullable(),
    currentAmortization: z.preprocess(
      (v) => (isEmpty(v) ? null : getAsNumber(v)),
      z.number({
        required_error: "form:error.required",
        invalid_type_error: "form:error.required",
      })
    ),
  })
  .refine(
    (schema) => {
      const isRefinance = schema.insuranceQuestionRefinanceOrRenewal === "YES";
      const addFunds =
        schema.insuranceQuestionAddFundsOrIncreasedMortgageAmount;

      // make sure addFunds is filled when isRefinance is YES
      if (isRefinance && isEmpty(addFunds)) {
        return false;
      }
      return true;
    },
    {
      message: "form:error.required",
      path: ["insuranceQuestionAddFundsOrIncreasedMortgageAmount"],
    }
  )
  .refine(
    (schema) => {
      const isRefinance = schema.insuranceQuestionRefinanceOrRenewal === "YES";
      const increasedAmortization =
        schema.insuranceQuestionIncreasedAmortization;

      // make sure increasedAmortization is filled when isRefinance is YES
      if (isRefinance && isEmpty(increasedAmortization)) {
        return false;
      }
      return true;
    },
    {
      message: "form:error.required",
      path: ["insuranceQuestionIncreasedAmortization"],
    }
  )
  .refine(
    (schema) => {
      const addFunds =
        schema.insuranceQuestionAddFundsOrIncreasedMortgageAmount;
      const increasedAmortization =
        schema.insuranceQuestionIncreasedAmortization;

      // make sure lastClosingDate is filled when addFunds or increasedAmortization is filled
      if (
        (addFunds === "YES" && isEmpty(schema.lastClosingDate)) ||
        (increasedAmortization === "YES" && isEmpty(schema.lastClosingDate))
      ) {
        return false;
      }
      return true;
    },
    {
      message: "form:error.required",
      path: ["lastClosingDate"],
    }
  )
  .refine(
    (schema) => {
      const isInsured = schema.insuranceQuestionOriginallyInsured === "YES";

      if (schema.currentAmortization > 25 && isInsured) {
        return false;
      }
      return true;
    },
    {
      message: "renewing-property:currentAmortization.error",
      path: ["currentAmortization"],
    }
  );

export const renewingMortgageSchema = z
  .object({
    balance: amountSchema,
    interestRate: z
      .number({
        required_error: "form:error.required",
        invalid_type_error: "form:error.required",
        coerce: false,
      })
      .max(100, { message: "form:error.maxInterest" }),
    interestRateType: z.enum(
      ["FIXED", "VARIABLE", "ADJUSTABLE", "CAPPED_VARIABLE"],
      {
        errorMap: () => {
          return { message: "form:error.required" };
        },
      }
    ),
    lender: z.nativeEnum(LenderEnum, {
      errorMap: () => {
        return { message: "form:error.required" };
      },
    }),
    mortgageType: z.enum(["STANDARD", "HELOC"], {
      errorMap: () => {
        return { message: "form:error.required" };
      },
    }),
    payment: z.object({
      amount: z.preprocess(
        (v) => (isEmpty(v) ? null : getAsNumber(v)),
        z
          .number({
            required_error: "form:error.missingAmount",
            invalid_type_error: "form:error.missingAmount",
            coerce: false,
          })
          .max(9_999_999, { message: "form:error.maxDollarAmount" })
      ),
      frequency: z.enum(
        [
          "WEEKLY",
          "ACCELERATED_WEEKLY",
          "BIWEEKLY",
          "ACCELERATED_BIWEEKLY",
          "SEMIMONTHLY",
          "MONTHLY",
        ],
        {
          errorMap: () => {
            return { message: "form:error.missingFrequency" };
          },
        }
      ),
    }),
    termType: z.enum(["OPEN", "CLOSED", "CONVERTABLE"], {
      errorMap: () => {
        return { message: "form:error.required" };
      },
    }),
  })
  .and(mortgageInsuranceSchema)
  .array()
  .optional()
  .nullable();
