import { useCallback } from "react";

import {
  getApplication,
  getApplications,
  getApplicationsExternalBroker,
  getApplicationsByApplicantId,
  getApplicationsSearchResults,
  getAssignedRepresentative,
  getCommunicationPreferences,
  updateCommunicationPreferences,
  getDownPayment,
  getApplicationsSearchExternalBrokerResults,
} from "@shared/api/applications";
import {
  GetDocumentsResponse,
  createCoApplicant,
  deleteCoApplicant,
  setOtherIncomesSpecified,
  setOwnedPropertiesSpecified,
  updateApplicantInfo,
} from "@shared/api/applications/applicants";
import { keyFactory } from "@shared/api/hooks/utils";
import { ApplicationState } from "@shared/constants";
import { TOAST_AUTOCLOSE_DELAY_IN_MS } from "@shared/constants";
import { ApplicantTypeEnum } from "@shared/constants/application/applicant.enum";
import {
  getApplicationTypeByTransactionType,
  getApplicationsByTransactionType,
  getApplicationsByType,
  getDocumentEntity,
  getFirstApplications,
} from "@shared/utils";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import {
  combineTargetProps,
  getApplicationMinimumDownPayment,
  getDownPaymentPercentage,
  getHasMinPercentDownpayment,
  getIsApplicantComplete,
  getIsBlockByBankruptcy,
  getRemainingDownPayment,
} from "./utils";

import type {
  Applicant,
  Application,
  ApplicationsPayload,
  ApplicationsResponse,
  AssignedAdvisor,
  CoApplicantCreate,
  CommunicationPreferences,
  DownPayment,
  NavigationStates,
} from "@shared/constants";
import type { UseQueryOptions } from "@tanstack/react-query";

export const applicationKeys = keyFactory("application");

const assignedRepresentativeKeys = keyFactory("assigned-representative");

const communicationPreferenceKeys = keyFactory("communication-preference");

export const downPaymentKeys = keyFactory("down-payment");

export type UseApplicationsSelect = <T>(data: Application[]) => T;

export const useGetCommunicationPreferences = <
  TResult = CommunicationPreferences,
>(
  select?: (data: CommunicationPreferences) => TResult
) => {
  return useQuery(
    communicationPreferenceKeys.list(),
    getCommunicationPreferences,
    {
      select,
    }
  );
};

export const useMutateCommunicationPreferences = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (communicationPreferences: CommunicationPreferences) =>
      updateCommunicationPreferences(communicationPreferences),
    onSettled() {
      queryClient.invalidateQueries({
        queryKey: communicationPreferenceKeys.list(),
      });
    },
    retry: 3,
  });
};

export const useGetApplicationsByApplicantId = <TResult = Application[]>(
  select?: (data: Application[]) => TResult
) => {
  return useQuery(applicationKeys.list(), getApplicationsByApplicantId, {
    select,
  });
};

export const useGetApplicationsByType = (type: Application["type"]) =>
  useGetApplicationsByApplicantId(getApplicationsByType(type));

export const useGetApplicationsByTransactionType = (
  transactionType: Application["type"]
) =>
  useGetApplicationsByApplicantId(
    getApplicationsByTransactionType(transactionType)
  );

export const useGetFirstApplication = () =>
  useGetApplicationsByApplicantId(getFirstApplications);

export const useGetApplications = <TResult = ApplicationsResponse>(
  payload: ApplicationsPayload & { isExternalBroker: boolean },
  options?: Pick<
    UseQueryOptions<ApplicationsResponse, unknown, TResult>,
    "select" | "enabled" | "keepPreviousData"
  >
) =>
  useQuery(
    ["applications", payload],
    () => {
      const { isExternalBroker, ...restPayload } = payload;

      return isExternalBroker
        ? getApplicationsExternalBroker(restPayload)
        : getApplications(restPayload);
    },
    options
  );

export const useGetApplication = <TResult = Application>({
  id,
  select,
  onError,
}: {
  id?: number;
  select?: (data: Application) => TResult;
  onError?: (error: unknown) => void;
}) => {
  return useQuery(
    applicationKeys.detail({ id }),
    // we use the `enabled` flag because we want to make sure that the application is loaded
    // before we call `getApplication()`
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    () => getApplication(id!),
    {
      enabled: !!id,
      select,
      onError,
    }
  );
};

export const useGetApplicationsSearchResults = <TResult = ApplicationsResponse>(
  payload: ApplicationsPayload & { isExternalBroker: boolean },
  options?: Pick<
    UseQueryOptions<ApplicationsResponse, unknown, TResult>,
    "select" | "enabled"
  >
) =>
  useQuery(
    ["applications/minified", payload],
    () => {
      const { isExternalBroker, ...restPayload } = payload;

      return isExternalBroker
        ? getApplicationsSearchExternalBrokerResults(restPayload)
        : getApplicationsSearchResults(restPayload);
    },
    options
  );

export const useGetApplicationLockedStatus = (applicationId: number) => {
  return useGetApplication({
    id: applicationId,
    select: useCallback((application: Application) => application.locked, []),
  });
};

/**
 * Get if a file can be deleted according to the state (stage) of the application
 *
 * A client can’t remove a document after it’s approved
 * @param applicationId application id
 * @returns
 */
export const useGetIsFileDeletable = (applicationId: number) => {
  return useGetApplication({
    id: applicationId,
    select: useCallback(
      (application: Application) =>
        [
          ApplicationState.Created,
          ApplicationState.Submitted,
          ApplicationState.UnderRevision,
          ApplicationState.Reviewed,
          ApplicationState.NotesSubmitted,
          ApplicationState.LenderSubmitted,
          ApplicationState.LenderApproved,
          ApplicationState.PendingCommitmentSignature,
          ApplicationState.PendingConditions,
        ].includes(application.applicationState),
      []
    ),
  });
};

export const useGetApplicationFundedStatus = (applicationId: number) => {
  return useGetApplication({
    id: applicationId,
    select: useCallback(
      (application: Application) =>
        application.applicationState === ApplicationState.Funded,
      []
    ),
  });
};

export const useGetApplicationSubmittedToUnderwriting = (
  applicationId: number
) => {
  return useGetApplication({
    id: applicationId,
    select: useCallback(
      (application: Application) =>
        [
          ApplicationState.NotesSubmitted,
          ApplicationState.LenderSubmitted,
          ApplicationState.LenderApproved,
        ].includes(application.applicationState),
      []
    ),
  });
};

export const useGetApplicationConditionalApproval = (applicationId: number) => {
  return useGetApplication({
    id: applicationId,
    select: useCallback(
      (application: Application) =>
        [
          ApplicationState.PendingCommitmentSignature,
          ApplicationState.PendingConditions,
        ].includes(application.applicationState),
      []
    ),
  });
};

export const useGetApplicationFinalApproval = (applicationId: number) => {
  return useGetApplication({
    id: applicationId,
    select: useCallback(
      (application: Application) =>
        [ApplicationState.Complete, ApplicationState.NotaryAlerted].includes(
          application.applicationState
        ),
      []
    ),
  });
};
export const useGetApplicationClosedStatus = (applicationId: number) => {
  return useGetApplication({
    id: applicationId,
    select: useCallback(
      (application: Application) =>
        [ApplicationState.Closed, ApplicationState.Expired].includes(
          application.applicationState
        ),
      []
    ),
  });
};

export const useGetDownPayment = <TResult = DownPayment>({
  applicationId,
  select,
}: {
  applicationId: number;
  select?: (data: DownPayment) => TResult;
}) => {
  return useQuery(
    downPaymentKeys.detail({ id: applicationId }),
    () => getDownPayment(applicationId),
    { select }
  );
};

// Memoizes https://tkdodo.eu/blog/react-query-data-transformations
export const useGetDownPaymentTotal = (applicationId: number) => {
  return useGetDownPayment({
    applicationId,
    select: useCallback((downPayment: DownPayment) => downPayment.total, []),
  });
};

export const useGetDownPaymentByApplicant = (applicationId: number) => {
  return useGetDownPayment({
    applicationId,
    select: useCallback(
      (downPayment: DownPayment) => Object.values(downPayment.applicants),
      []
    ),
  });
};

export const useGetDownPaymentPercentage = (applicationId: number) => {
  return useGetApplication({
    id: applicationId,
    select: getDownPaymentPercentage,
  });
};

export const useGetIsDownPaymentViable = (applicationId: number) => {
  return useGetApplication({
    id: applicationId,
    select: getHasMinPercentDownpayment,
  });
};

export const useGetMinimumDownPayment = (applicationId: number) => {
  return useGetApplication({
    id: applicationId,
    select: getApplicationMinimumDownPayment,
  });
};

export const useGetRemainingDownPayment = (applicationId: number) => {
  return useGetApplication({
    id: applicationId,
    select: getRemainingDownPayment,
  });
};

export const useGetSubjectProperty = (applicationId: number) => {
  return useGetApplication({
    id: applicationId,
    select: (application) => {
      const { property } = application;

      return property;
    },
  });
};

export const useGetApplicationType = (applicationId: number) => {
  return useGetApplication({
    id: applicationId,
    select: useCallback(
      (application: Application) =>
        getApplicationTypeByTransactionType(application.type),
      []
    ),
  });
};

export const useGetDocumentEntityDetails = (document: GetDocumentsResponse) =>
  useGetApplication({
    id: document.applicationId,
    select: (application) => getDocumentEntity(document, application),
  });

const filterApplicantsGuarantor =
  (withGarantor: boolean) => (application: Application) => {
    let applicants = Object.values(application.applicants);

    // Filter out guarantors if we don't need them
    if (!withGarantor) {
      applicants = applicants.filter((applicant) => !applicant.guarantor);
    }

    return applicants;
  };

export const useGetApplicants = (applicationId: number, withGarantor = true) =>
  useGetApplication({
    id: applicationId,
    select: filterApplicantsGuarantor(withGarantor),
  });

export const useGetApplicantLength = (
  applicationId: number,
  withGarantor = true
) =>
  useGetApplication({
    id: applicationId,
    select: useCallback(
      (application: Application) => {
        return filterApplicantsGuarantor(withGarantor)(application).length;
      },
      [withGarantor]
    ),
  });

export const useGetApplicantById = (
  applicationId: number,
  applicantId?: number
) =>
  useGetApplication<Applicant | undefined>({
    id: applicationId,
    select: useCallback(
      (application: Application) =>
        applicantId ? application.applicants[applicantId] : undefined,
      [applicantId]
    ),
  });

/**
 * This function will return the applicant with the given id, or the main applicant, or the first applicant.
 * This is useful for when you want to get an applicant, but you don't know if the applicant exists.
 */
export const useGetApplicantByIdWithFallback = (
  applicationId: number,
  applicantId: number
) =>
  useGetApplication<Applicant | undefined>({
    id: applicationId,
    select: ({ applicants, mainApplicantId }) => {
      return (
        applicants[applicantId] || // try to get the applicant with the given id
        applicants[mainApplicantId] || // the applicant with the given id doesn't exist, try to get the main applicant
        Object.values(applicants)[0] // the main applicant doesn't exist, fallback to the first applicant in the list
      );
    },
  });

export const useGetMainApplicant = (applicationId: number) =>
  useGetApplication<Applicant | undefined>({
    id: applicationId,
    select: (application) => {
      const mainApplicantId = application.mainApplicantId;

      return application.applicants[mainApplicantId] || undefined;
    },
  });

export const useGetCoApplicants = (applicationId: number) =>
  useGetApplication<Applicant[]>({
    id: applicationId,
    select: (application) => {
      const mainApplicantId = application.mainApplicantId;

      return Object.values(application.applicants).filter(
        (applicant) => applicant.applicantId !== mainApplicantId
      );
    },
  });

export const useGetApplicantType = (
  applicationId: number,
  applicantId: number
) =>
  useGetApplication<ApplicantTypeEnum | undefined>({
    id: applicationId,
    select: useCallback(
      (application: Application) => {
        const isMainApplicant = application.mainApplicantId === applicantId;
        const isGuarantor = application.applicants[applicantId]?.guarantor;

        if (isMainApplicant) {
          return ApplicantTypeEnum.MAIN_APPLICANT;
        }
        if (isGuarantor) {
          return ApplicantTypeEnum.GUARANTOR;
        }

        return ApplicantTypeEnum.CO_APPLICANT;
      },
      [applicantId]
    ),
  });

export const useMutateApplicant = (
  applicationId: number,
  applicantId?: number
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (newApplicantInfo: Applicant) =>
      updateApplicantInfo(applicationId, applicantId || 0, newApplicantInfo),
    onMutate: async (newApplicantInfo: Applicant) => {
      // Cancel current queries for the application by id
      await queryClient.cancelQueries({
        queryKey: applicationKeys.detail({ id: applicationId }),
      });

      // Snapshot the previous value
      const previousApplication = queryClient.getQueryData<Application>(
        applicationKeys.detail({ id: applicationId })
      );

      // Create optimistic applicant
      const optimisticApplicantInfo = newApplicantInfo;

      // Add optimistic applicant to application
      if (applicantId && previousApplication) {
        queryClient.setQueryData<Application>(
          applicationKeys.detail({ id: applicationId }),
          {
            ...previousApplication,
            applicants: {
              [applicantId]: optimisticApplicantInfo,
            },
          }
        );
      }
      // Return context with the optimistic application
      return { previousApplication };
    },
    onSuccess: (response, newApplicantInfo, context) => {
      // Replace optimistic applicant information in the application with the result
      if (applicantId && context?.previousApplication) {
        queryClient.setQueryData<Application>(
          applicationKeys.detail({ id: applicationId }),
          {
            ...context?.previousApplication,
            applicants: {
              [applicantId]: newApplicantInfo,
            },
          }
        );
      }
    },
    onError: (error, newApplicantInfo, context) => {
      // Remove optimistic applicant from the application
      if (applicantId && context?.previousApplication) {
        queryClient.setQueryData<Application>(
          applicationKeys.detail({ id: applicationId }),
          { ...context?.previousApplication }
        );
      }
    },
    // use `onSuccess` set data or easy and safe way to refetch all the app ?
    // Always refetch after error or success:
    // onSettled: () => {
    //   queryClient.invalidateQueries({ queryKey: applicationKeys.detail({ id: applicationId }) });
    // },
    retry: 3,
  });
};

export const useMutateCreateCoApplicant = (applicationId: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (newCoApplicant: CoApplicantCreate) =>
      createCoApplicant(applicationId, newCoApplicant),
    onSettled() {
      // Reload the selected application
      queryClient.invalidateQueries({
        queryKey: applicationKeys.detail({ id: applicationId }),
      });
    },
    retry: 3,
  });
};

export const useDeleteCoApplicant = (
  applicationId: number,
  applicantId: number
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => deleteCoApplicant(applicationId, applicantId),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: applicationKeys.detail({ id: applicationId }),
      });
    },
    retry: 2,
  });
};

export const useApplicationSectionsStates = (applicationId: number) =>
  useGetApplication<NavigationStates>({
    id: applicationId,
    select: (application) => {
      if (application.applicants) {
        const applicants = Object.values(application.applicants)

          .map((applicant) => ({
            id: applicant.applicantId,
            ...getIsApplicantComplete(applicant),
          }))
          .reduce(
            (acc, currentValue) => ({
              ...acc,
              [currentValue.id]: currentValue,
            }),
            {}
          );

        const isBlockByBankruptcy = getIsBlockByBankruptcy(
          Object.values(application.applicants)
        );

        const applicationType = getApplicationTypeByTransactionType(
          application.type
        );

        return {
          subjectProperty: isBlockByBankruptcy
            ? false
            : combineTargetProps(application.property, applicationType) ||
              false,
          downpayment: isBlockByBankruptcy
            ? false
            : getHasMinPercentDownpayment(application),
          ...applicants,
        } as NavigationStates;
      }
      return {} as NavigationStates;
    },
  });

export const useGetAssignedRepresentative = <TResult = AssignedAdvisor>(
  applicationId: number,
  select?: (data: AssignedAdvisor) => TResult
) => {
  return useQuery(
    assignedRepresentativeKeys.detail({ id: applicationId }),
    () => getAssignedRepresentative(applicationId),
    {
      select,
    }
  );
};

export const useMutateOtherIncomeSpecified = (
  applicationId: number,
  applicantId: number
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (specified: boolean) =>
      setOtherIncomesSpecified(applicationId, applicantId, { specified }),
    onSettled() {
      // Reset the query
      queryClient.invalidateQueries({
        queryKey: applicationKeys.detail({ id: applicationId }),
      });
    },
  });
};

export const useMutateOwnedPropertiesSpecified = (
  applicationId: number,
  applicantId: number
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (specified: boolean) =>
      setOwnedPropertiesSpecified(applicationId, applicantId, { specified }),
    onSettled() {
      // Reset the query
      queryClient.invalidateQueries({
        queryKey: applicationKeys.detail({ id: applicationId }),
      });
    },
  });
};
