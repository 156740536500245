import { useMemo } from "react";

import { Button, Tooltip, useBreakpointValue } from "@nestoca/ui";
import { useGetApplicationsByApplicantId } from "@shared/api/hooks/applications";
import { ApplicationState } from "@shared/constants";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import styles from "./delete-account-button.module.scss";
export const DeleteAccountButton = ({ onClick }: { onClick: () => void }) => {
  const { t } = useTranslation("common");
  const isMobile = useBreakpointValue({ default: true, md: false });

  const { data: applications } = useGetApplicationsByApplicantId();

  const isDeleteAccountDisabled = useMemo(() => {
    const appInProgress = applications?.find((application) => {
      return ![ApplicationState.Created, ApplicationState.Closed].includes(
        application.applicationState
      );
    });
    return !!appInProgress;
  }, [applications]);

  return (
    <>
      <Button
        variant="primary"
        disabled={isDeleteAccountDisabled}
        onClick={onClick}
        data-tooltip-id={"delete-account"}
        className={clsx(styles["delete-button"], {
          [styles["delete-button--desktop"]]: !isMobile,
        })}
        data-dd-action-name="delete account"
      >
        {t("accountDeletion.button")}
      </Button>
      {isDeleteAccountDisabled && (
        <Tooltip
          id={"delete-account"}
          className={clsx({ [styles["delete-button__tooltip"]]: isMobile })}
          title={t("accountDeletion.feedback.applicationInProgress")}
          subText={t("accountDeletion.feedback.applicationInProgress1")}
        />
      )}
    </>
  );
};
