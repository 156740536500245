import { useMemo, useState } from "react";

import { Box } from "@nestoca/ui";
import { useGetApplicationsByApplicantId } from "@shared/api/hooks/applications";
import { TransactionTypeEnum } from "@shared/constants";
import { useTypedRouter } from "@shared/utils";
import Link from "next/link";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { useApplicationsFilters } from "../../hooks/use-applications-filters";
import { ChipFilters, ChipFilterItem } from "../chip-filters";
import {
  ScrollAreaRoot,
  ScrollAreaScrollbar,
  ScrollAreaThumb,
  ScrollAreaViewport,
} from "../scroll-area";

import styles from "./application-selector.module.scss";

const schema = z.object({
  transactionType: z.nativeEnum(TransactionTypeEnum).optional(),
});

export const ApplicationChipFilters = () => {
  const { query } = useTypedRouter(schema);
  const { t } = useTranslation("common");
  const [activeChipFilter, setActiveChipFilter] = useState<
    TransactionTypeEnum | undefined
  >(query.transactionType);

  const { data: applications } = useGetApplicationsByApplicantId();

  const filters = useApplicationsFilters(applications || []);

  // if the active filter is not in the list then we should take the first one
  useMemo(() => {
    if (filters.length > 0) {
      setActiveChipFilter(query.transactionType || filters[0].value);
    }
  }, [query.transactionType, filters]);

  return (
    <ScrollAreaRoot>
      <ScrollAreaViewport>
        <Box className={styles["chips-container"]}>
          <ChipFilters
            filters={filters}
            activeItemValue={activeChipFilter}
            // NOTE: let's override the default filter item because here we want to have a link `a` tag
            // use the default but wrap it with an anchor
            renderItem={({ filter, activeItemValue, onSelectFilter }) => {
              return (
                <Link
                  href={{
                    pathname: "/",
                    query: {
                      transactionType: filter.value,
                    },
                  }}
                >
                  <ChipFilterItem
                    filter={filter}
                    onClick={onSelectFilter}
                    activeItemValue={activeItemValue}
                  />
                </Link>
              );
            }}
          />
        </Box>
      </ScrollAreaViewport>
      <ScrollAreaScrollbar orientation="horizontal">
        <ScrollAreaThumb />
      </ScrollAreaScrollbar>
    </ScrollAreaRoot>
  );
};
