import { useEffect } from "react";

import { Flex, FlexProps, Typography, ariaAttr } from "@nestoca/ui";
import clsx from "clsx";

import styles from "./reachable.module.scss";
import { useReachableContext } from "./use-reachable";
import { ReachableItemContext, useReachableItem } from "./use-reachable-item";
import { scrollToReachableItem } from "./utils";

export interface ReachableItemHeaderProps extends Omit<FlexProps, "children"> {
  showCounter?: boolean;
  onToggle?: () => void;
  e2ePrefix?: string;
  children:
    | React.ReactNode
    | ((props: ReachableItemContext) => React.ReactNode);
}

export const ReachableItemHeader = ({
  className,
  children,
  onToggle,
  e2ePrefix,
  ...rest
}: ReachableItemHeaderProps) => {
  const itemContext = useReachableItem();
  const { itemLength } = useReachableContext();

  const {
    headerId,
    contentId,
    isUnreached,
    isClosed,
    isReached,
    isOpen,
    index,
    state,
    showCounter,
  } = itemContext;

  useEffect(() => {
    if (isOpen) {
      scrollToReachableItem(headerId);
    }
  }, [headerId, isOpen]);

  const e2eId = e2ePrefix ? `${e2ePrefix}-` : "";

  return (
    <Flex
      id={headerId}
      className={clsx(
        styles.reachable__item__header,
        {
          [styles["reachable__item__header--unreached"]]: isUnreached,
        },
        className
      )}
      justify="between"
      align="start"
      data-state={state}
      data-unreached={ariaAttr(isUnreached)}
      data-reached={ariaAttr(isReached)}
      data-open={ariaAttr(isOpen)}
      data-closed={ariaAttr(isClosed)}
      aria-disabled={ariaAttr(!isReached)}
      role="button"
      aria-expanded={ariaAttr(isOpen)}
      aria-controls={contentId}
      onClick={onToggle}
      data-testid={`${e2eId}header-reachable-item`}
      {...rest}
    >
      <Flex direction="column" grow={1}>
        {showCounter && !!itemLength && (
          <Typography className={clsx(styles.reachable__item__counter)}>
            {index + 1} / {itemLength}
          </Typography>
        )}
        {typeof children === "function" ? children(itemContext) : children}
      </Flex>
    </Flex>
  );
};

ReachableItemHeader.id = "ReachableItemHeader";
