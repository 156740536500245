import { Button, Flex, Typography } from "@nestoca/ui";
import { Application, CIRCLE_PROGRESS_TRACKER_PHASES } from "@shared/constants";
import { useCircleProgressTracker } from "@shared/ui";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import { ActiveModal, statusesIcons } from "./circle-progress-tracker";
import { TrackerPhase } from "./tracker-phase";
import styles from "./tracker-phase.module.scss";

type GettingToKnowType = {
  applicantId: number;
  application: Application;
  isActive: boolean;
  setActivePhase: (activePhase: CIRCLE_PROGRESS_TRACKER_PHASES) => void;
  setActiveModal: (activeModal: ActiveModal) => void;
};
export const LenderApproval = ({
  isActive,
  applicantId,
  application,
  setActivePhase,
  setActiveModal,
}: GettingToKnowType) => {
  const { t } = useTranslation("applications");

  const {
    lender_approval: { completePercent, statusesList },
  } = useCircleProgressTracker({
    application,
    applicantId,
    statusIcons: statusesIcons,
  });

  return (
    <>
      <TrackerPhase
        isActive={isActive}
        completePercent={completePercent}
        phaseNumber={2}
        phase={CIRCLE_PROGRESS_TRACKER_PHASES.LENDER_APPROVAL}
        setActivePhase={setActivePhase}
        totalCompletePercent={completePercent}
      >
        <Flex direction="column" className={styles["phase__body"]} gap={5}>
          <Flex gap={3} direction="column" className={styles["phase__list"]}>
            {statusesList.map((state) => {
              return (
                <Flex key={state.name} align="center" gap={2}>
                  <Flex align="center" shrink={0}>
                    {state.icon}
                  </Flex>
                  <Typography
                    className={clsx(styles["phase__status"], {
                      [styles["phase__status--active"]]:
                        state.status !== "pending",
                    })}
                  >
                    {t(state.name)}
                  </Typography>
                </Flex>
              );
            })}
          </Flex>
          <Button
            variant="ghost"
            onClick={() => setActiveModal("lender_approval")}
            className={styles["phase__button"]}
          >
            {t("moreDetails")}
          </Button>
        </Flex>
      </TrackerPhase>
    </>
  );
};
