import { useGate } from "@nestoca/gate-react";
import { useTenant } from "@nestoca/multi-tenant";
import { Box, ButtonIcon, Flex, HStack, Typography, VStack } from "@nestoca/ui";
import { useGetAssignedRepresentative } from "@shared/api/hooks/applications";
import { useTranslation } from "react-i18next";
import { BsCheck, BsFiles } from "react-icons/bs";
import useClipboard from "react-use-clipboard";

import styles from "./advisor-contact.module.scss";

export const AdvisorContact = ({
  applicationId,
}: {
  applicationId: number;
}) => {
  const { t } = useTranslation("applications");
  const tenant = useTenant();

  const gate = useGate();
  const isExternalBroker = gate.getRole() === "externalbroker";

  const { data: assignedAdvisor } = useGetAssignedRepresentative(applicationId);

  const [isCopied, setCopied] = useClipboard(assignedAdvisor?.phone ?? "", {
    // `isCopied` will go back to `false` after 1000ms.
    successDuration: 1000,
  });

  // in case advisor info is missing or mapping is incorrect and assigned Advisor is Karim Benabdallah
  const hasAssignedAdvisor =
    assignedAdvisor &&
    assignedAdvisor.id &&
    assignedAdvisor.firstName !== "Karim" &&
    assignedAdvisor.lastName !== "Benabdallah";

  const hasAssignedAdvisorOrExtBroker = hasAssignedAdvisor || isExternalBroker;

  const defaultContact = {
    name: "Tony Le",
    phone: "514-526-2442",
    email: "tony.le@nesto.ca",
  };

  const contact = hasAssignedAdvisor
    ? {
        name: `${assignedAdvisor.firstName} ${assignedAdvisor.lastName}`,
        phone: assignedAdvisor.phone,
        email: assignedAdvisor.email,
      }
    : defaultContact;

  return (
    <>
      {hasAssignedAdvisorOrExtBroker ? (
        <Flex direction="column" className={styles["progress-modal-advisor"]}>
          <VStack align="start">
            <VStack align="start" gap={0}>
              <Typography size="00" className={styles["subheader"]}>
                {hasAssignedAdvisor
                  ? t("advisorName")
                  : t("underwriter", { ns: "common" })}
              </Typography>
              <Typography size={0} weight={7}>
                {contact.name}
              </Typography>
            </VStack>
            <VStack align="start" gap={0}>
              <Typography size="00" className={styles["subheader"]}>
                {t("phone")}
              </Typography>
              <HStack>
                <Typography size={0} weight={7}>
                  {contact.phone}
                </Typography>
                <ButtonIcon
                  aria-label="copy"
                  icon={
                    isCopied ? (
                      <BsCheck color="var( --color-status-success-foreground)" />
                    ) : (
                      <BsFiles color="black" />
                    )
                  }
                  backgroundColor="transparent"
                  size="small"
                  className={styles["btn"]}
                  onClick={() => setCopied()}
                  data-dd-action-name="copy phone"
                />
              </HStack>
            </VStack>
            <VStack align="start" gap={0}>
              <Typography size="00" className={styles["subheader"]}>
                {t("email")}
              </Typography>
              <HStack>
                <Typography size={0} weight={7}>
                  <a href={`mailto:${contact.email}`}>
                    <Typography size={0} weight={7}>
                      {contact.email}
                    </Typography>
                  </a>
                </Typography>
                <ButtonIcon
                  aria-label="copy"
                  icon={
                    isCopied ? (
                      <BsCheck color="var( --color-status-success-foreground)" />
                    ) : (
                      <BsFiles color="black" />
                    )
                  }
                  backgroundColor="transparent"
                  size="small"
                  className={styles["btn"]}
                  onClick={() => setCopied()}
                  data-dd-action-name="copy email"
                />
              </HStack>
            </VStack>
          </VStack>
        </Flex>
      ) : (
        tenant && (
          <Box className={styles["progress-modal-advisor"]}>
            <VStack align="start" gap={0}>
              <Typography size="00" className={styles["subheader"]}>
                {t("email")}
              </Typography>
              <HStack>
                <a href={`mailto:${tenant.applicationSupportEmail}`}>
                  <Typography size={0} weight={7}>
                    {tenant.applicationSupportEmail}
                  </Typography>
                </a>
                <ButtonIcon
                  aria-label="copy"
                  icon={
                    isCopied ? (
                      <BsCheck color="var( --color-status-success-foreground)" />
                    ) : (
                      <BsFiles color="black" />
                    )
                  }
                  backgroundColor="transparent"
                  size="small"
                  className={styles["btn"]}
                  onClick={() => setCopied()}
                  data-dd-action-name="copy email"
                />
              </HStack>
            </VStack>
          </Box>
        )
      )}
    </>
  );
};
