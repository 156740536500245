import { ReactNode } from "react";

import { Flex } from "@nestoca/ui";
import { CIRCLE_PROGRESS_TRACKER_PHASES } from "@shared/constants";
import clsx from "clsx";
import { BsChevronLeft } from "react-icons/bs";

import { PhaseHeader } from "./phase-header";
import styles from "./tracker-phase.module.scss";

type TrackerPhaseType = {
  isActive: boolean;
  phaseNumber: number;
  phase: CIRCLE_PROGRESS_TRACKER_PHASES;
  setActivePhase: (activePhase: CIRCLE_PROGRESS_TRACKER_PHASES) => void;
  completePercent: number | [number, number] | undefined;
  totalCompletePercent: number | undefined;
  children: ReactNode;
};

export const TrackerPhase = ({
  isActive,
  phaseNumber,
  phase,
  completePercent,
  totalCompletePercent,
  children,
  setActivePhase,
}: TrackerPhaseType) => {
  return (
    <Flex
      onClick={() => setActivePhase(phase)}
      direction={isActive ? "column" : "row"}
      className={clsx(styles.phase, { [styles["phase--open"]]: isActive })}
      gap={5}
      align="start"
    >
      <PhaseHeader
        phaseNumber={phaseNumber}
        phase={phase}
        isActive={isActive}
        completePercent={completePercent || 0}
        totalCompletePercent={totalCompletePercent}
      />
      {isActive ? children : <BsChevronLeft size={16} />}
    </Flex>
  );
};
