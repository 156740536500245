import { useContext } from "react";

import { TenantEnvVarContext } from "@shared/utils/tenant-env-var-provider";

type EnvVarNames = "GOOGLE_PLACES_API_KEY";

export const useTenantEnvVar = (envName: EnvVarNames) => {
  const context = useContext(TenantEnvVarContext);

  if (context === undefined)
    throw new Error(
      "useTenantEnvVarContext must be used within an TenantEnvVarContext Provider"
    );

  return context?.[envName] ?? "";
};
