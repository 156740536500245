import { useMemo } from "react";

import { useTenantFlags } from "@nestoca/multi-tenant";
import {
  Box,
  Button,
  Flex,
  FlexProps,
  Skeleton,
  Typography,
  useBreakpointValue,
} from "@nestoca/ui";
import { useGetAccount } from "@shared/api/hooks/account";
import {
  useGetApplicationsByApplicantId,
  useGetApplicationsByTransactionType,
} from "@shared/api/hooks/applications";
import { Application, TransactionTypeEnum } from "@shared/constants";
import { DatadogMask } from "@shared/datadog";
import {
  ApplicationSelectorGrid,
  ApplicationSelectorGridSkeleton,
  useApplicationsFilters,
} from "@shared/ui";
import { getApplicantName } from "@shared/utils";
import { useTypedRouter } from "@shared/utils";
import { Trans, useTranslation } from "react-i18next";
import { BsHouse, BsHouseFill } from "react-icons/bs";
import { z } from "zod";

import { ApplicationChipFilters } from "../application-selector/application-chip-filters";

import styles from "./home-page.module.scss";

const schema = z.object({
  transactionType: z.nativeEnum(TransactionTypeEnum).optional(),
});

interface HomePageProps {
  getApplicationUrl: (application: Application) => URL | string;
}
type BreakpointValueType = {
  wrappersGap: FlexProps["gap"];
  applicationsGap: FlexProps["gap"];
};
export type ApplicationWithIndex = Application & { index: number };
export const HomePage = ({ getApplicationUrl }: HomePageProps) => {
  const { t } = useTranslation("applications");
  const { query } = useTypedRouter(schema);
  const { enableNewApplicationCreate } = useTenantFlags();
  const { data: allApplications } = useGetApplicationsByApplicantId();
  const filters = useApplicationsFilters(allApplications || []);

  const isMobile = useBreakpointValue({ default: true, md: false });
  const breakpointValue = useBreakpointValue({
    default: { wrappersGap: 0, applicationsGap: 4 } as BreakpointValueType,
    md: { wrappersGap: 7, applicationsGap: 6 } as BreakpointValueType,
  });

  const {
    data: applications,
    isLoading,
    isError,
  } = useGetApplicationsByTransactionType(
    query.transactionType || filters?.[0]?.value
  );

  const { data: account, isLoading: isLoadingAccount } = useGetAccount();
  const applicantName = getApplicantName(account, t);

  const splitApplications = useMemo(
    () =>
      applications?.reduce(
        (acc, currApplication, i) => {
          if (currApplication.applicationState === "FUNDED") {
            acc.fundedApplications.push({ ...currApplication, index: i });
          } else {
            acc.openApplications.push({ ...currApplication, index: i });
          }
          return acc;
        },
        {
          fundedApplications: [] as ApplicationWithIndex[],
          openApplications: [] as ApplicationWithIndex[],
        }
      ),
    [applications]
  );

  return (
    <Flex
      direction="column"
      gap={breakpointValue?.wrappersGap}
      align="center"
      className={styles.home}
    >
      <Flex
        className={styles["home-wrapper-header"]}
        direction="column"
        gap={breakpointValue?.wrappersGap}
      >
        <Flex className={styles["home-header"]} direction="column">
          {isMobile && (
            <Typography size={3} weight={7}>
              {t("portfolio")}
            </Typography>
          )}
          {isLoadingAccount && (
            <Skeleton containerClassName={styles.home__applicant} />
          )}
          {account && (
            <Typography weight={7} className={styles["home-text"]}>
              <Trans
                t={t}
                i18nKey="account.header"
                values={{
                  name: applicantName,
                }}
                components={[
                  <DatadogMask
                    key={0}
                    className={styles["home-name"]}
                    data-dd-aaction-name="applicant name"
                  >
                    {applicantName}
                  </DatadogMask>,
                ]}
              />
            </Typography>
          )}
        </Flex>
        {isMobile && enableNewApplicationCreate && (
          <Button
            className={styles["add-button"]}
            variant="primary"
            size="medium"
            data-dd-action-name="add new"
          >
            {t("addNew")}
          </Button>
        )}
        <ApplicationChipFilters />
      </Flex>
      {/* skeleton while loadings */}
      {isLoading && (
        <Flex
          gap={breakpointValue?.applicationsGap}
          className={styles.applications}
        >
          <Flex gap={3}>
            <BsHouse size={20} />
            <Box>
              <Skeleton width={126} />
            </Box>
          </Flex>
          <ApplicationSelectorGridSkeleton />
        </Flex>
      )}
      {splitApplications && splitApplications.openApplications.length > 0 && (
        <Flex
          gap={breakpointValue?.applicationsGap}
          className={styles.applications}
        >
          <Flex gap={3}>
            <BsHouse size={20} />
            <Box>{t("openApplications")}</Box>
          </Flex>
          <ApplicationSelectorGrid
            getApplicationUrl={getApplicationUrl}
            applications={splitApplications?.openApplications ?? []}
          />
        </Flex>
      )}
      {splitApplications && splitApplications.fundedApplications.length > 0 && (
        <Flex
          gap={breakpointValue?.applicationsGap}
          className={styles.applications}
        >
          <Flex gap={3}>
            <BsHouseFill size={20} color="var(--color-green-500)" />
            <Box>{t("fundedMortgages")}</Box>
          </Flex>
          <ApplicationSelectorGrid
            getApplicationUrl={getApplicationUrl}
            applications={splitApplications?.fundedApplications}
          />
        </Flex>
      )}
      {isError && <Box>{t("failedToLoadApplications", { ns: "common" })}</Box>}
    </Flex>
  );
};
