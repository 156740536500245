import { useCallback, useMemo } from "react";

import { useGate } from "@nestoca/gate-react";
import { useTenant } from "@nestoca/multi-tenant";
import { Box, Modal, Typography } from "@nestoca/ui";
import { useGetAssignedRepresentative } from "@shared/api/hooks/applications";
import { TOAST_AUTOCLOSE_DELAY_IN_MS } from "@shared/constants";
import { useApplicationDisabled } from "@shared/ui";
import { Trans, useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { advisorOptionType } from "../contact";
import { ContactItem } from "../contact/contact-item";

import styles from "./locked-application-modal.module.scss";

type LockedApplicationModalPropsType = {
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
  applicationId: number;
  heading: JSX.Element;
  isFunded?: boolean;
  isLocked?: boolean;
  isClosed?: boolean;
};

export const LockedApplicationModal = ({
  isModalOpen,
  setIsModalOpen,
  applicationId,
  heading,
}: LockedApplicationModalPropsType) => {
  const { t } = useTranslation("applications");

  const { data: assignedAdvisor } = useGetAssignedRepresentative(applicationId);
  const {
    isFunded,
    isLocked,
    isClosed,
    isSubmittedToUnderwriter,
    isConditionalApproval,
    isFinalApproval,
  } = useApplicationDisabled(applicationId);
  const tenant = useTenant();

  const gate = useGate();
  const isExternalBroker = gate.getRole() === "externalbroker";

  // in case advisor info is missing or mapping is incorrect and assigned Advisor is Karim Benabdallah
  const hasAssignedAdvisor =
    assignedAdvisor &&
    assignedAdvisor.id &&
    assignedAdvisor?.firstName !== "Karim" &&
    assignedAdvisor?.lastName !== "Benabdallah";

  const options = useMemo(() => {
    const options: advisorOptionType[] = [];

    if (hasAssignedAdvisor) {
      options.push(
        {
          id: "name",
          name: t("name"),
          data: `${assignedAdvisor?.firstName} ${assignedAdvisor?.lastName}`,
        },
        {
          id: "phone",
          name: t("phone"),
          data: assignedAdvisor?.phone as string,
          canCopy: true,
        }
      );
    } else if (isExternalBroker) {
      options.push(
        {
          id: "name",
          name: t("name", { ns: "docs" }),
          data: "Tony Le",
        },
        {
          id: "phone",
          name: t("phone", { ns: "docs" }),
          data: "514-526-2442",
          canCopy: true,
        },
        {
          id: "email",
          name: t("email", { ns: "docs" }),
          data: "tony.le@nesto.ca",
          canCopy: true,
        }
      );
    } else {
      options.push({
        id: "email",
        name: t("email"),
        data: tenant?.applicationSupportEmail || "",
        canCopy: true,
      });
    }

    return options;
  }, [hasAssignedAdvisor, assignedAdvisor, t, tenant, isExternalBroker]);

  const onClick = useCallback(
    (option: advisorOptionType) => {
      const text = isExternalBroker
        ? t(`copiedToClipboard.${option.id}.underwriter`, {
            data: option.name,
            ns: "applications",
          })
        : t("contact.valueCopied", { data: option.name });

      return toast(text, {
        type: "success",
        autoClose: TOAST_AUTOCLOSE_DELAY_IN_MS,
      });
    },
    [t, isExternalBroker]
  );

  const applicationHasStatus =
    isFunded ||
    isClosed ||
    isSubmittedToUnderwriter ||
    isConditionalApproval ||
    isFinalApproval;

  const isApplicationLocked = !applicationHasStatus && isLocked;

  const statusText = [
    { status: isFunded, text: "fundedApplication.modal.text" },
    { status: isClosed, text: "closedApplication.modal.text" },
    { status: isFinalApproval, text: "finalApprovalApplication.modal.text" },
    {
      status: isSubmittedToUnderwriter,
      text: "submittedToUnderwriterApplication.modal.text",
    },
    {
      status: isConditionalApproval,
      text: "conditionalApprovalApplication.modal.text",
    },
  ];

  return (
    <Modal
      visible={isModalOpen}
      scrollable
      className={styles["locked-application-modal"]}
      closeOnOutsideClick
      onClose={() => {
        setIsModalOpen(false);
      }}
    >
      <Modal.Header
        showCloseButton
        onClose={() => {
          setIsModalOpen(false);
        }}
        className={styles["modal-header"]}
        closeLabel={`${t("close", { ns: "common" })}`}
      >
        <Typography
          size={3}
          weight={7}
          className={styles["modal-header__heading"]}
        >
          {heading}
        </Typography>
      </Modal.Header>
      <Modal.Body>
        {isApplicationLocked && (
          <Typography
            className={styles["modal-body__text"]}
            size={0}
            weight={5}
          >
            <Trans
              t={t}
              i18nKey="lockedApplication.modal.noAccess"
              components={[<strong key={0} />, <strong key={1} />]}
            />
          </Typography>
        )}
        <Typography className={styles["modal-body__text"]} size={0} weight={5}>
          {isExternalBroker && isClosed
            ? t("closedApplication.modal.text", { ns: "docs" })
            : statusText.map(({ status, text }) => status && t(text))}
          {isApplicationLocked && t("lockedApplication.modal.uploadDocuments")}
        </Typography>
        <Typography className={styles["modal-body__text"]} size={0} weight={5}>
          {hasAssignedAdvisor
            ? applicationHasStatus
              ? t("lockedApplication.modal.contactAdvisorStatus")
              : isApplicationLocked
                ? t("lockedApplication.modal.contactAdvisorLocked")
                : null
            : isExternalBroker
              ? t("lockedApplication.modal.contactUnderwriterStatus", {
                  ns: "documents",
                })
              : t("lockedApplication.modal.missingAdvisorInfo")}
        </Typography>
        <Box className={styles.contact}>
          {options.map((option) => (
            <ContactItem
              optionItem={option}
              onClick={() => onClick(option)}
              className={styles["contact-item"]}
              key={option.id}
            />
          ))}
        </Box>
      </Modal.Body>
    </Modal>
  );
};
