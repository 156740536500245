import { useRef } from "react";

import { Box, Modal } from "@nestoca/ui";

import { RemoveCoApplicantModalBody } from "./remove-co-applicant-modal-body";
import styles from "./remove-co-applicant-modal.module.scss";

type RemoveCoApplicantModalProps = {
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
};

export const RemoveCoApplicantModal = ({
  isModalOpen,
  setIsModalOpen,
}: RemoveCoApplicantModalProps) => {
  const modalRef = useRef<HTMLDivElement>(null);

  return (
    <Modal
      visible={isModalOpen}
      closeOnOutsideClick
      onClose={() => setIsModalOpen(false)}
      className={styles["remove-co-applicant-modal"]}
      e2ePrefix="remove-co-applicant"
    >
      <Box ref={modalRef} style={{ height: modalRef.current?.clientHeight }}>
        <Modal.Header
          showCloseButton
          onClose={() => setIsModalOpen(false)}
          className={styles["remove-co-applicant-modal__header"]}
        />
        <Modal.Body>
          <RemoveCoApplicantModalBody />
        </Modal.Body>
      </Box>
    </Modal>
  );
};
