import { Modal } from "@nestoca/ui";
import { useTranslation } from "react-i18next";

import { applicationSelectorOptionType } from "../application-selector/application-selector-option";

import { ApplicationSettingsModalBody } from "./application-settings-modal-body";
import styles from "./application-settings-modal.module.scss";

export type ApplicationSettingsModalProps = {
  getApplicationOptions: applicationSelectorOptionType[];
  applicationId: number;
  applicantId: number;
  onClose?: () => void;
};

export const ApplicationSettingsModal = ({
  isModalOpen,
  setIsModalOpen,
  applicationId,
  applicantId,
  getApplicationOptions,
}: ApplicationSettingsModalProps & {
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
}) => {
  const { t } = useTranslation("common");

  return (
    <>
      <Modal
        visible={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        closeOnOutsideClick
        className={styles["modal"]}
      >
        <Modal.Header
          showCloseButton
          onClose={() => {
            setIsModalOpen(false);
          }}
          closeLabel={`${t("close")}`}
          className={styles["modal__header"]}
        />
        <Modal.Body>
          <ApplicationSettingsModalBody
            applicationId={applicationId}
            applicantId={applicantId}
            getApplicationOptions={getApplicationOptions}
            onClose={() => {
              setIsModalOpen(false);
            }}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};
