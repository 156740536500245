import { useEffect, useState } from "react";

import { Flex, isEmpty, Typography, useBreakpointValue } from "@nestoca/ui";
import {
  Application,
  PROGRESS_STAGES_STATUSES,
  CIRCLE_PROGRESS_TRACKER_PHASES,
} from "@shared/constants";
import { useCircleProgressTracker } from "@shared/ui";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { BsCheckCircleFill, BsCircle, BsCircleFill } from "react-icons/bs";

import { ProgressIconsType } from "../progress-tracker.types";

import { CircleProgressClosed } from "./circle-progress-closed";
import styles from "./circle-progress-tracker.module.scss";
import { Funding } from "./funding";
import { GettingToKnow } from "./getting-to-know";
import { GettingToKnowModal } from "./getting-to-know-modal";
import { LenderApproval } from "./lender-approval";
import { LenderApprovalModal } from "./lender-approval-modal";

type PropsType = {
  application: Application;
  applicantId: number;
};
export type ActiveModal = "getting_to_know" | "lender_approval" | null;

export const statusesIcons: ProgressIconsType = {
  [PROGRESS_STAGES_STATUSES.ACTIVE]: (
    <BsCircleFill size={15} color={"var(--color-blueberry-600)"} />
  ),
  [PROGRESS_STAGES_STATUSES.COMPLETE]: (
    <BsCheckCircleFill
      size={15}
      color={"var(--color-status-success-foreground-saturated)"}
    />
  ),
  [PROGRESS_STAGES_STATUSES.PENDING]: (
    <BsCircle size={15} color={"var(--color-gray-500)"} />
  ),
};

const PHASE_COMPONENTS = [
  {
    component: GettingToKnow,
    phase: CIRCLE_PROGRESS_TRACKER_PHASES.GETTING_TO_KNOW,
  },
  {
    component: LenderApproval,
    phase: CIRCLE_PROGRESS_TRACKER_PHASES.LENDER_APPROVAL,
  },
  {
    component: Funding,
    phase: CIRCLE_PROGRESS_TRACKER_PHASES.FUNDING,
  },
];

export const CircleProgressTracker = ({
  application,
  applicantId,
}: PropsType) => {
  const { t } = useTranslation("docs");
  const isMobile = useBreakpointValue({ default: true, md: false });

  const tracker = useCircleProgressTracker({
    application,
    applicantId,
  });
  const { currentProgressPhase, getting_to_know, lender_approval } = tracker;

  const [activePhase, setActivePhase] =
    useState<CIRCLE_PROGRESS_TRACKER_PHASES>(currentProgressPhase);
  const [activeModal, setActiveModal] = useState<ActiveModal>(null);

  useEffect(() => {
    setActivePhase(currentProgressPhase);
  }, [currentProgressPhase]);

  if (isEmpty(tracker)) {
    return <CircleProgressClosed />;
  }

  return (
    <Flex
      direction="column"
      className={clsx(styles.progress, {
        [styles["progress--desktop"]]: !isMobile,
      })}
      gap={5}
    >
      {!isMobile && (
        <Typography className={styles["progress__header"]}>
          {t("appProgress")}
        </Typography>
      )}
      {PHASE_COMPONENTS.map(({ component: PhaseComponent, phase }) => (
        <PhaseComponent
          key={phase}
          isActive={activePhase === phase}
          setActivePhase={setActivePhase}
          application={application}
          applicantId={applicantId}
          setActiveModal={setActiveModal}
        />
      ))}
      <GettingToKnowModal
        applicationId={application.id}
        isModalOpen={
          activeModal === CIRCLE_PROGRESS_TRACKER_PHASES.GETTING_TO_KNOW
        }
        setActiveModal={setActiveModal}
        phaseNumber={1}
        phase={CIRCLE_PROGRESS_TRACKER_PHASES.GETTING_TO_KNOW}
        totalCompletePercent={getting_to_know.completePercent}
        completePercent={[
          getting_to_know.questionnaireCount.completePercent,
          getting_to_know.documentsCount.completePercent,
        ]}
      />
      <LenderApprovalModal
        applicationId={application.id}
        isModalOpen={
          activeModal === CIRCLE_PROGRESS_TRACKER_PHASES.LENDER_APPROVAL
        }
        setActiveModal={setActiveModal}
        phaseNumber={2}
        phase={CIRCLE_PROGRESS_TRACKER_PHASES.LENDER_APPROVAL}
        completePercent={lender_approval.completePercent || 0}
      />
    </Flex>
  );
};
