import * as z from "zod";

// *** NOTE: importing from @shared/utils is not working ***
// *** so we are importing with relative path the functions here ***
// It create a circular dependency
import { isValidYear } from "../../utils/dates";
import { getAsNumber } from "../../utils/get-as-number";
import { isEmpty } from "../../utils/is-empty";
import {
  HeatingTypeEnum,
  SewageTypeEnum,
  TargetPropertyStyleEnum,
  TargetPropertyTenureEnum,
  TargetPropertyTypeEnum,
  WaterTypeEnum,
  addressSchema,
  rentalIncomeAmountFrequency,
  rentalIncomeAmountFrequencyOptional,
} from "../application";
import {
  amountSchema,
  amountUnitSchema,
  coOwnersheepFeesOptional,
  garageSchema,
} from "../target-property";

import { pastDateSchema } from "./validation/date.validation";
import { renewingMortgageSchema } from "./validation/mortgage.validation";

export const purposeUnion = z.discriminatedUnion("purpose", [
  z.object({
    purpose: z.enum(["OWNER_OCCUPIED", "SECONDARY_RESIDENCE"], {
      required_error: "form:error.required",
    }),
    rentalIncome: rentalIncomeAmountFrequencyOptional.nullable().optional(),
  }),
  z.object({
    purpose: z.enum(["OWNER_OCCUPIED_AND_RENTAL", "RENTAL"], {
      errorMap: () => {
        return { message: "form:error.required" };
      },
    }),
    rentalIncome: rentalIncomeAmountFrequency,
  }),
]);

export const basePropertySchema = z
  .object({
    setRegisteredAddress: z.any().optional(),
    estimatedPropertyValue: amountSchema,
    purchasePrice: amountSchema,
    originalMortgageAmount: amountSchema,
    rentalIncome: rentalIncomeAmountFrequencyOptional.nullable().optional(),
    purchaseDate: pastDateSchema,
    purpose: z.enum(
      [
        "OWNER_OCCUPIED",
        "OWNER_OCCUPIED_AND_RENTAL",
        "RENTAL",
        "SECONDARY_RESIDENCE",
      ],
      {
        errorMap: () => {
          return { message: "form:error.required" };
        },
      }
    ),
    address: addressSchema,
    selectedAddress: z.any(),
    yearBuilt: z.preprocess(
      (v) => (isEmpty(v) ? null : getAsNumber(v)),
      z.number({ invalid_type_error: "form:error.required" }).refine(
        (data) => {
          return isValidYear(data);
        },
        {
          message: "form:error.invalidYear",
        }
      )
    ),
    propertyStyle: z.nativeEnum(TargetPropertyStyleEnum, {
      errorMap: () => {
        return { message: "form:error.required" };
      },
    }),
    propertyType: z.nativeEnum(TargetPropertyTypeEnum, {
      errorMap: () => {
        return { message: "form:error.required" };
      },
    }),
    tenure: z.nativeEnum(TargetPropertyTenureEnum, {
      errorMap: () => {
        return { message: "form:error.required" };
      },
    }),
    schoolAndMunicipalTaxes: z.object({
      amount: amountSchema,
      year: z.preprocess(
        (v) => (isEmpty(v) ? null : getAsNumber(v)),
        z.number({
          required_error: "form:error.required",
          invalid_type_error: "form:error.required",
        })
      ),
    }),
    livingSpace: amountUnitSchema,
    lotSize: amountUnitSchema,
    heatingType: z.nativeEnum(HeatingTypeEnum, {
      errorMap: () => {
        return { message: "form:error.required" };
      },
    }),
    heatingCostIncluded: z.boolean().nullable().optional(),
    heatingCost: z
      .object({
        amount: z.number().nullable().optional(),
        frequency: z
          .enum(
            [
              "WEEKLY",
              "BIWEEKLY",
              "MONTHLY",
              "SEMIMONTHLY",
              "SEMIANNUALLY",
              "ANNUALLY",
              "",
            ],
            {
              errorMap: () => {
                return { message: "form:error.required" };
              },
            }
          )
          .optional()
          .nullable(),
      })
      .nullable()
      .optional(),
    coOwnershipFees: coOwnersheepFeesOptional,
    waterType: z.nativeEnum(WaterTypeEnum, {
      errorMap: () => {
        return { message: "form:error.required" };
      },
    }),
    sewageType: z.nativeEnum(SewageTypeEnum, {
      errorMap: () => {
        return { message: "form:error.required" };
      },
    }),
    garage: garageSchema,
    mortgages: renewingMortgageSchema,
  })
  .and(purposeUnion);

// create has additional field setRegisteredAddress
export const createPropertySchema = z
  .discriminatedUnion("setRegisteredAddress", [
    z.object({
      setRegisteredAddress: z.literal(true),
    }),
    z.object({
      setRegisteredAddress: z.literal(false),
      address: addressSchema,
    }),
  ])
  .and(basePropertySchema);

export const renewingPropertySchema = z.union([
  createPropertySchema,
  basePropertySchema,
]);

export type RenewingPropertyForm = z.infer<typeof renewingPropertySchema>;
