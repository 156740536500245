import { useState } from "react";

import {
  Box,
  Button,
  Chip,
  Flex,
  Modal,
  Typography,
  useBreakpointValue,
} from "@nestoca/ui";
import { KeepI18ExprHappy } from "@shared/utils";
import { useTranslation } from "react-i18next";

import { AdvisorContact } from "./advisor-contact";
import { ProgressModalAllStages } from "./progress-modal-all-stages";
import styles from "./progress-modal.module.scss";
import stepStyles from "./progress-step.module.scss";
import { ProgressItem } from "./progress-tracker.types";

type PropsType = {
  applicationId: number;
  setIsModalOpen: (isModalOpen: boolean) => void;
  stage: ProgressItem;
};
export const ProgressModalContent = ({
  setIsModalOpen,
  applicationId,
  stage,
}: PropsType) => {
  const { t } = useTranslation("docs");
  const isMobile = useBreakpointValue({ default: true, md: false });
  const [showAllStages, setShowAllStages] = useState(false);
  const { name, status } = stage;

  const MultilineTranslation = (text: string) => {
    const items = text.split("\n");
    return (
      <>
        {items.map((item) => (
          <p key={item}>{item}</p>
        ))}
      </>
    );
  };

  if (showAllStages) {
    return (
      <ProgressModalAllStages
        applicationId={applicationId}
        setShowAllStages={setShowAllStages}
        showAllStages={showAllStages}
        setIsModalOpen={setIsModalOpen}
      />
    );
  }

  return (
    <>
      <Modal.Header
        showCloseButton
        className={styles["progress-modal-header"]}
        onClose={() => {
          setIsModalOpen(false);
        }}
        closeLabel={t("close", { ns: "common" })}
      >
        <Flex direction="column">
          <Box className={styles["progress-modal-text"]}>
            {t("progressTracker")}
          </Box>
          <Box className={styles["progress-modal-header-name"]}>
            {t(`progressTracker.${name}` as KeepI18ExprHappy)}
          </Box>
        </Flex>
      </Modal.Header>
      <Modal.Body className={styles["progress-modal-body"]}>
        <Box>
          <Flex gap={2} className={styles["progress-modal-body-stage"]}>
            <Box className={styles["progress-modal-text"]}>
              {t("progress.modal.statusHeading")}
            </Box>
            <Chip label={t(status)} className={stepStyles[`chip-${status}`]} />
          </Flex>
          <Box className={styles["progress-modal-description-heading"]}>
            {t("progress.modal.descriptionHeading")}
          </Box>
          <Typography className={styles["progress-modal-text"]}>
            {MultilineTranslation(
              t(`progress.modal.description.${name}` as KeepI18ExprHappy)
            )}
          </Typography>
          <AdvisorContact applicationId={applicationId} />
        </Box>
        {isMobile && (
          <Button
            variant="secondary"
            className={styles["progress-modal-body-button"]}
            onClick={() => setShowAllStages(true)}
            data-dd-action-name="view all stages"
          >
            {t("progress.modal.allStages.button")}
          </Button>
        )}
      </Modal.Body>
    </>
  );
};
