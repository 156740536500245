import { ExternalConnection } from "@shared/constants";
import { TenantConnectionConfig } from "@shared/utils";

export const config: TenantConnectionConfig<ExternalConnection | undefined> = {
  ig: {
    prod: "ig-sf",
    qa: "ig-sf-qa",
    staging: undefined, // keep undefined for local dev and staging we dont need to defined an SSO
    demo: "ig-sf-qa",
  },
  "ig-dev": {
    prod: undefined,
    qa: "ig-sf-dev",
    staging: "ig-sf-dev",
    demo: "ig-sf-dev",
  },
  nesto: {
    prod: undefined,
    qa: undefined,
    staging: undefined,
    demo: undefined,
  },
};
