import { useState, useEffect, ChangeEvent } from "react";

import { FormElement, Input } from "@nestoca/ui";
import {
  useGetCommunicationPreferences,
  useMutateCommunicationPreferences,
} from "@shared/api/hooks/applications";
import {
  CommunicationPreferences,
  CommunicationPreferencesType,
} from "@shared/constants";
import Debug from "debug";

import { Preferences } from "./communication-preferences";
import styles from "./communication-preferences.module.scss";

const debug = Debug("nesto:user-settings");

interface CheckboxProps {
  setting: Preferences;
  method: "email" | "sms";
}

export const Checkbox = ({ setting, method }: CheckboxProps) => {
  const { data: communicationPreferences } = useGetCommunicationPreferences();
  const communicationPreferencesMutation = useMutateCommunicationPreferences();
  const type = setting.id as CommunicationPreferencesType;

  const initialCheckValue =
    communicationPreferences?.[type]?.[
      method as keyof CommunicationPreferences[CommunicationPreferencesType]
    ] || false;

  const [checked, setChecked] = useState(initialCheckValue);

  useEffect(() => {
    if (initialCheckValue !== undefined) {
      setChecked(initialCheckValue);
    }
  }, [initialCheckValue]);

  const counterpartType = method === "email" ? "sms" : "email";
  const counterpartChecked =
    (setting[counterpartType] &&
      communicationPreferences?.[type]?.[counterpartType]) ||
    false;

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;

    if (communicationPreferences) {
      const updatedCommunicationPreferences: CommunicationPreferences = {
        ...communicationPreferences,
        [type]: {
          ...communicationPreferences?.[type],
          [method]: isChecked,
        },
      };

      communicationPreferencesMutation.mutate(updatedCommunicationPreferences, {
        onSuccess: () => {
          debug("handleChange onSuccess");
          setChecked(isChecked);
        },
        onError: (error) => {
          debug("handleChange onError", error);
        },
      });
    }
  };

  const hasBothMethods =
    setting.email !== undefined && setting.sms !== undefined;

  return (
    <FormElement
      className={styles["checkbox-wrapper"]}
      isDisabled={checked && !counterpartChecked && hasBothMethods}
    >
      <Input
        type="checkbox"
        checked={checked}
        className={styles.checkbox}
        onChange={handleChange}
      />
    </FormElement>
  );
};
