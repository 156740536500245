import * as z from "zod";

// *** NOTE: importing from @shared/utils is not working ***
// *** so we are importing with relative path the functions here ***
// It create a circular dependency
// import { dateIsFuture, getAsNumber, isEmpty } from "@shared/utils";
import { dateIsFuture } from "../../utils/dates";
import { getAsNumber } from "../../utils/get-as-number";
import { isEmpty } from "../../utils/is-empty";
import { optionalNumber } from "../generic";

import { addressSchema } from "./address.validation";
import { AmountFrequencyEnum } from "./applicant.enum";

export const amountSchema = z.preprocess(
  (v) => (isEmpty(v) ? null : getAsNumber(v)),
  z
    .number({
      required_error: "form:error.required",
      invalid_type_error: "form:error.required",
      coerce: false,
    })
    .max(9_999_999, { message: "form:error.maxDollarAmount" })
    .min(1, { message: "form:error.required" })
);

export const optionalAmount = z.preprocess(
  (v) => (isEmpty(v) ? null : getAsNumber(v)),
  z.number().nullable().optional()
);

export const rentalIncomeAmountFrequency = z.object({
  amount: z
    .preprocess(
      (v) => (isEmpty(v) ? null : getAsNumber(v)),
      z.number({
        required_error: "form:error.missingAmount",
        invalid_type_error: "form:error.missingAmount",
        coerce: false,
      })
    )

    .refine(
      (v) => {
        // latest version 3.22.2 of zod has a bug with the preprocessor
        // so we will do the convertion here again to be sure we have the right type
        // https://github.com/colinhacks/zod/issues/2671
        const value = isEmpty(v) ? null : getAsNumber(v);
        if (!value) {
          return false;
        }

        return value > 0;
      },
      {
        message: "form:error.required",
      }
    ),
  frequency: z.nativeEnum(AmountFrequencyEnum, {
    errorMap: () => {
      return { message: "form:error.missingFrequency" };
    },
  }),
});

// duplicate name to avoid breaking changes
export const rentalIncomeAmountFrequencyOptional = z
  .object({
    amount: z
      .preprocess(
        (v) => (isEmpty(v) ? null : getAsNumber(v)),
        z
          .number({
            required_error: "form:error.required",
            invalid_type_error: "type",
            coerce: false,
          })
          .max(9_999_999, {
            message: "form:error.maxDollarAmount",
          })
          .nullable()
      )
      .optional(),
    frequency: z.preprocess(
      (v) => (isEmpty(v) ? "" : v),
      z
        .enum([
          "WEEKLY",
          "BIWEEKLY",
          "MONTHLY",
          "SEMIMONTHLY",
          "SEMIANNUALLY",
          "ANNUALLY",
          "",
        ])
        .nullable()
        .optional()
    ),
  })
  // make sure if one is filled, the other is filled
  .refine(
    (schema) => {
      // frequency is not empty and amount is null / empty field
      if (!isEmpty(schema.frequency) && isEmpty(schema.amount)) {
        return false;
      }

      return true;
    },
    {
      message: "form:error.missingAmount",
      path: ["amount"],
    }
  )
  .refine(
    (schema) => {
      // we want to support negative values for the amount and just check it is not 0
      return schema.amount && schema.amount !== 0 ? !!schema.frequency : true;
    },
    {
      message: "form:error.missingFrequency",
      path: ["frequency"],
    }
  );

export const condoFeesSchema = z
  .object({
    amount: z
      .preprocess(
        (v) => (isEmpty(v) ? null : getAsNumber(v)),
        z
          .number({
            required_error: "form:error.required",
            invalid_type_error: "form:error.required",
            coerce: false,
          })
          .max(9_999_999, "form:error.maxDollarAmount")
      )
      .optional(),
    frequency: z
      .enum([
        "ANNUALLY",
        "SEMIANNUALLY",
        "MONTHLY",
        "SEMIMONTHLY",
        "BIWEEKLY",
        "WEEKLY",
        "",
      ])
      .optional(),
    heatingIncluded: z.boolean().nullable().optional(),
  })
  // make sure if one is filled, the other is filled
  .refine(
    (schema) => {
      // frequency is not empty and amount is null / empty field
      if (!isEmpty(schema.frequency) && isEmpty(schema.amount)) {
        return false;
      }

      return true;
    },
    {
      message: "form:error.required",
      path: ["amount"],
    }
  )
  .refine(
    (schema) => {
      // we want to support negative values for the amount and just check it is not 0
      return schema.amount && schema.amount !== 0 ? !!schema.frequency : true;
    },
    {
      message: "form:error.required",
      path: ["frequency"],
    }
  )
  .nullable()
  .optional();

export type CondoFeesType = z.infer<typeof condoFeesSchema>;

export const baseSchema = z.object({
  id: z.number().optional(),
  address: addressSchema,
  currentPurpose: z.enum(
    ["OWNER_OCCUPIED", "RENTAL", "OWNER_OCCUPIED_AND_RENTAL"],
    {
      required_error: "form:error.required",
      invalid_type_error: "form:error.required",
    }
  ),
  afterTransactionPurpose: z.enum(
    ["OWNER_OCCUPIED", "RENTAL", "OWNER_OCCUPIED_AND_RENTAL", "SOLD"],
    {
      required_error: "form:error.required",
      invalid_type_error: "form:error.required",
    }
  ),
  type: z.string().trim().optional().nullable(),
  estimatedPropertyValue: optionalNumber,
  taxes: z.object({ amount: optionalAmount, year: optionalNumber }).optional(),
  hasMortgage: z.boolean().optional().nullable(),
  rentalIncome: rentalIncomeAmountFrequencyOptional.nullable().optional(),
  condoFees: condoFeesSchema,
  currentSaleStatus: z.string().trim().optional().nullable(),
  sellingDate: z.string().trim().optional().nullable(),
  purchasePrice: optionalNumber,
});

export const mortgageSchema = z.object({
  mortgage: z.object({
    balance: amountSchema,
    interestRate: z
      .number({
        required_error: "form:error.required",
        invalid_type_error: "form:error.required",
      })
      .max(100, "form:error.maxInterest"),
    interestRateType: z.enum(
      ["FIXED", "VARIABLE", "ADJUSTABLE", "CAPPED_VARIABLE"],
      {
        required_error: "form:error.required",
        invalid_type_error: "form:error.required",
      }
    ),
    lender: z
      .string({
        required_error: "form:error.required",
        invalid_type_error: "form:error.required",
      })
      .trim(),
    maturityDate: z.string().trim().nonempty(),
    mortgageType: z.enum(["STANDARD", "HELOC"], {
      required_error: "form:error.required",
      invalid_type_error: "form:error.required",
    }),
    payment: z.object({
      amount: amountSchema,
      frequency: z.enum(
        [
          "WEEKLY",
          "ACCELERATED_WEEKLY",
          "BIWEEKLY",
          "ACCELERATED_BIWEEKLY",
          "SEMIMONTHLY",
          "MONTHLY",
        ],
        {
          required_error: "form:error.required",
          invalid_type_error: "form:error.required",
        }
      ),
    }),
    termType: z.enum(["OPEN", "CLOSED", "CONVERTABLE"], {
      required_error: "form:error.required",
      invalid_type_error: "form:error.required",
    }),
  }),
});

export type Mortgage = z.infer<typeof mortgageSchema>;

export const optionalMortgage = z.object({
  mortgage: z
    .object({
      balance: optionalAmount,
      interestRate: optionalNumber,
      interestRateType: z.string().trim().optional(),
      lender: z.string().trim().optional(),
      maturityDate: z.string().trim().optional(),
      mortgageType: z.string().trim().optional(),
      payment: z
        .object({
          amount: optionalAmount,
          frequency: z
            .enum([
              "WEEKLY",
              "ACCELERATED_WEEKLY",
              "BIWEEKLY",
              "ACCELERATED_BIWEEKLY",
              "SEMIMONTHLY",
              "MONTHLY",
              "",
            ])
            .nullable()
            .optional(),
        })
        .optional()
        .nullable(),
      termType: z.string().trim().optional(),
    })
    .partial()
    .optional(),
});

export type OptionalMortgage = z.infer<typeof optionalMortgage>;

export const mortgageSchemaUnion = z.discriminatedUnion("hasMortgage", [
  mortgageSchema.merge(
    z.object({
      hasMortgage: z.literal(true),
    })
  ),
  z.object({
    hasMortgage: z.literal(false),
  }),
]);

export const refinedMortgageSchema = z
  .object({
    hasMortgage: z.boolean().optional().nullable(),
  })
  .and(optionalMortgage)
  .superRefine((values, ctx) => {
    if (values.hasMortgage) {
      const isOk = mortgageSchema.safeParse(values);

      if (!isOk.success) {
        isOk.error.issues.forEach((issue) => {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "form:error.required",
            path: [...issue.path],
          });
        });
      }
    }
  });

export const rentalIncomeSchema = baseSchema
  .pick({
    afterTransactionPurpose: true,
    rentalIncome: true,
  })
  .superRefine((values, ctx) => {
    if (
      ["RENTAL", "OWNER_OCCUPIED_AND_RENTAL"].includes(
        values.afterTransactionPurpose
      )
    ) {
      const isOk = rentalIncomeAmountFrequency.safeParse(values.rentalIncome);

      if (!isOk.success) {
        isOk.error.issues.forEach((issue) => {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "form:error.required",
            path: ["rentalIncome", ...issue.path],
          });
        });
      }
    }
  });

export const soldSchema = baseSchema
  .pick({
    currentPurpose: true,
    afterTransactionPurpose: true,
  })
  .merge(
    z.object({
      currentSaleStatus: z.enum(
        ["NOT_READY_YET", "LISTED_FOR_SALE", "CONDITIONALLY_SOLD", "SOLD_FIRM"],
        {
          required_error: "form:error.required",
          invalid_type_error: "form:error.required",
        }
      ),
      sellingDate: z
        .string({
          required_error: "form:error.required",
          invalid_type_error: "form:error.required",
        })
        .trim()
        .refine(dateIsFuture, {
          message: "form:error.dateShouldBeFuture",
        }),
      purchasePrice: z
        .number({
          required_error: "form:error.required",
          invalid_type_error: "form:error.required",
        })
        .max(9_999_999, "form:error.maxDollarAmount")
        .refine((value) => value > 0, {
          message: "form:error.required",
        }),
    })
  );

export const heatingIncludedSchema = baseSchema
  .pick({
    condoFees: true,
  })
  .merge(
    z.object({
      type: z.enum(
        [
          "CONDO",
          "HOUSE",
          "SECONDARY_HOME_OR_COTTAGE",
          "DUPLEX",
          "TRIPLEX",
          "FOURPLEX",
        ],
        {
          required_error: "form:error.required",
          invalid_type_error: "form:error.required",
        }
      ),
    })
  )
  .superRefine((values, ctx) => {
    if (values.type === "CONDO") {
      const isOk = z.boolean().safeParse(values.condoFees?.heatingIncluded);

      if (!isOk.success) {
        isOk.error.issues.forEach((issue) => {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "form:error.required",
            path: ["condoFees.heatingIncluded", ...issue.path],
          });
        });
      }
    }
  });

export const propertyPurposeSchema = baseSchema
  .pick({
    currentPurpose: true,
  })
  .merge(
    z.object({
      estimatedPropertyValue: amountSchema,
      taxes: z.object({
        amount: amountSchema,
        year: z.number({
          required_error: "form:error.required",
          invalid_type_error: "form:error.required",
        }),
      }),
    })
  )
  .and(rentalIncomeSchema)
  .and(heatingIncludedSchema);

const hasMortgageSchema = baseSchema.superRefine((values, ctx) => {
  if (values.afterTransactionPurpose !== "SOLD") {
    const isOk = z.boolean().safeParse(values.hasMortgage);

    if (!isOk.success) {
      isOk.error.issues.forEach((issue) => {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "form:error.required",
          path: ["hasMortgage", ...issue.path],
        });
      });
    }
  }
});

export const schema = hasMortgageSchema.and(refinedMortgageSchema);

export const soldMortgageSchema = schema.and(soldSchema);

export type OwnedPropertyFormData = z.infer<typeof schema>;
