import { Chip } from "@nestoca/ui";
import clsx from "clsx";
import { IoCheckmark } from "react-icons/io5";

import styles from "./chip-filters.module.scss";
import { ChipFilter } from "./chip-filters.types";

export interface ChipFilterItemProps {
  filter: ChipFilter;
  onClick?: (filter: ChipFilter) => void;
  activeItemValue?: ChipFilter["value"] | Record<string, boolean>;
  hasCheckmark?: boolean;
}

export const ChipFilterItem = ({
  filter,
  onClick,
  activeItemValue,
  hasCheckmark = false,
}: ChipFilterItemProps) => {
  const isCheckmarkActive =
    typeof activeItemValue !== "string" && activeItemValue?.[filter.value];
  const isActive = activeItemValue === filter.value;

  const variant = hasCheckmark
    ? isCheckmarkActive
      ? "info"
      : "default"
    : isActive
      ? "info"
      : "default";

  const variantStyle = hasCheckmark && isCheckmarkActive ? "outline" : "solid";
  const additionalClasses =
    !hasCheckmark && isActive
      ? { [styles["chip-filter--active"]]: activeItemValue === filter.value }
      : {};

  return (
    <Chip
      leadingIcon={hasCheckmark && isCheckmarkActive && <IoCheckmark />}
      size="large"
      variant={variant}
      variantStyle={variantStyle}
      label={filter.label}
      disabled={filter.disabled}
      onClick={() => onClick?.(filter)}
      className={clsx(styles["chip-filter"], additionalClasses)}
      data-dd-action-name={filter.value}
    />
  );
};
